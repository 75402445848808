import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import Title from '../Title'
import Group from '../Group'
import { PreviewFieldProps } from '../PreviewField/PreviewField'
import PreviewField from '../PreviewField'
import { combinedFields } from '@dentalux/ui-library-core'
import { FieldSettings, Optins } from '@dentalux/ui-library-core'

type Props = {
  optins: Optins
  getEditableFieldProps: (settings: Omit<FieldSettings, '__type'>) => Omit<PreviewFieldProps, 'transform'>
}

export const SummaryOptins = ({ optins, getEditableFieldProps }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Title>{t('category.optins')}</Title>

      <Group>
        <Group>
          <Grid item xs={12}>
            <PreviewField
              {...getEditableFieldProps(combinedFields.confidentialityAgreementAccepted)}
              value={optins.confidentialityAgreementAccepted}
            />
          </Grid>
          <Grid item xs={12}>
            <PreviewField
              {...getEditableFieldProps(combinedFields.marketingCommunicationConsentAccepted)}
              value={optins.marketingCommunicationConsentAccepted}
            />
          </Grid>
        </Group>
      </Group>
    </>
  )
}
