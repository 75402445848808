import { useTranslation } from 'react-i18next'
import formatDefault from '@dentalux/common/date/formatDefault'
import { Grid } from '@mui/material'

import { StyledGridStreet, StyledGridEmail } from './GeneralInformation.styles'
import { isDateFormatted } from '../Content/Content.helpers'
import PreviewField, { PreviewFieldProps } from '../PreviewField/PreviewField'
import Title from '../Title'
import Group from '../Group'
import { combinedFields } from '@dentalux/ui-library-core'
import { Form, FieldSettings } from '@dentalux/ui-library-core'

type Props = {
  values: Partial<Form>
  getEditableFieldProps: (settings: Omit<FieldSettings, '__type'>) => Omit<PreviewFieldProps, 'transform'>
}

const SummaryGeneralInformation = ({ values, getEditableFieldProps }: Props) => {
  const { t } = useTranslation()

  const genderFieldProps = getEditableFieldProps(combinedFields.gender)
  const careLevelFieldProps = getEditableFieldProps(combinedFields.hasCareLevel)

  return (
    <>
      <Title>{t('category.general_information')}</Title>

      <Group>
        <Grid item xs={6} md={3}>
          <PreviewField
            {...genderFieldProps}
            transform={(value) => {
              return t(`option.${value?.toString()?.toLowerCase()}`, { defaultValue: genderFieldProps.value })
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.firstName)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.lastName)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField
            {...getEditableFieldProps(combinedFields.birthday)}
            transform={(value: string | boolean | number) =>
              isDateFormatted(value.toString()) ? value : formatDefault(value.toString())
            }
          />
        </Grid>

        <StyledGridStreet item xs={12} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.street)} />
        </StyledGridStreet>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.zipCode)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.city)} />
        </Grid>

        <StyledGridEmail item xs={12} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.email)} />
        </StyledGridEmail>

        <Grid item xs={12} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.phone)} />
        </Grid>

        <Grid item xs={12} md={12}>
          <PreviewField {...getEditableFieldProps(combinedFields.placeOfBirth)} />
        </Grid>
      </Group>

      <Group>
        <Grid item xs={6} md={6}>
          <PreviewField {...careLevelFieldProps} />
        </Grid>

        {careLevelFieldProps.value && (
          <Grid item xs={6} md={6}>
            <PreviewField {...getEditableFieldProps(combinedFields.careLevelLimitedInTime)} />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <PreviewField
            {...getEditableFieldProps(combinedFields.type)}
            transform={(value) => t(`option.${value?.toString().toLowerCase()}`, { defaultProps: value })}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.hasCivilServiceAllowance)} />
        </Grid>

        <Grid item xs={6} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.insuranceCompany)} />
        </Grid>

        <Grid item xs={6} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.dentalInsuranceCompany)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.hasFamilyInsurance)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.holderFirstName)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.holderLastName)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField
            {...getEditableFieldProps(combinedFields.holderBirthday)}
            transform={(value) => (isDateFormatted(value.toString()) ? value : formatDefault(value.toString()))}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.holderStreet)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.holderZipcode)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.holderCity)} />
        </Grid>
      </Group>

      <Group>
        <Grid item xs={12} md={12}>
          <PreviewField {...getEditableFieldProps(combinedFields.occupation)} />
        </Grid>
      </Group>

      <Group>
        <Grid item xs={6} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.isPregnant)} />
        </Grid>

        <Grid item xs={6} md={6}>
          <PreviewField {...getEditableFieldProps(combinedFields.pregnantMonths)} />
        </Grid>
      </Group>

      <Group>
        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.consumesAlcohol)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.consumesDrugs)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.isSmoker)} />
        </Grid>

        <Grid item xs={6} md={3}>
          <PreviewField {...getEditableFieldProps(combinedFields.cigarretesPerDay)} />
        </Grid>
      </Group>
    </>
  )
}

export default SummaryGeneralInformation
